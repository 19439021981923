<template>
    <ODataLookup :data-object="dsCalendarsLookup">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
            </span>
        </template>

        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="300"></OColumn>
        <OColumn field="StartDate" width="120"></OColumn>
        <OColumn field="FinishDate" width="120"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { defineProps } from 'vue';
import { ODataLookup } from 'o365-datalookup';

const props = defineProps({
    is: String,
    textInputValue: String,
    textInput: Boolean
});

const dsCalendarsLookup = $getDataObjectById("dsSystem_CalendarsLookup");

</script>